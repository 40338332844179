<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-dialog :persistent="!canClose">
    <template #default="{ isActive }">
      <v-card :class="backgroundColors">
        <template v-if="$slots.title">
          <v-card-title class="tw-flex tw-align-center tw-font-normal" :class="titleClass">
            <h3 class="tw-w-full tw-break-words">
              <slot name="title" />
            </h3>
            <v-tooltip v-if="canClose" location="top">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  density="compact"
                  class="tw-ml-auto"
                  variant="text"
                  @click="isActive.value = false"
                  icon
                >
                  <XMarkIcon class="tw-size-6" />
                </v-btn>
              </template>
              Close
            </v-tooltip>
          </v-card-title>
          <v-divider :class="dividerClasses" />
        </template>

        <v-card-text :class="[mainContentClass, 'tw-p-4']">
          <slot />
        </v-card-text>

        <v-card-actions v-if="$slots.actions">
          <div class="tw-ml-auto">
            <slot name="actions" :closeDialog="() => (isActive.value = false)" />
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useThemeSetting } from '@/composables/useThemeSetting';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const {
  canClose = true,
  mainContentClass,
  titleClass
} = defineProps<{
  mainContentClass?: string;
  titleClass?: string;
  canClose?: boolean;
}>();

const { backgroundColors, dividerClasses } = useThemeSetting();
</script>
