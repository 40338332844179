<template>
  <div>
    <v-text-field
      ref="searchEl"
      theme="light"
      :placeholder="t(`PI5['orders.search']`) + '…'"
      hide-details
      rounded
      readonly
      @click="searchDialogOpen = true"
      data-testid="main-search-input"
    >
      <template #prepend-inner>
        <MagnifyingGlassIcon class="tw-size-5 tw-text-grey-600" />
      </template>
      <template #append-inner>
        <div class="tw-flex tw-gap-1 tw-items-center">
          <v-chip class="tw-px-2" label>
            <span v-if="isMacOS" v-html="CommandKeyMacOSIcon"></span>
            <span v-else>Ctrl</span>
            + K
          </v-chip>
        </div>
      </template>
    </v-text-field>
    <Dialog
      v-model="searchDialogOpen"
      width="50%"
      min-width="360"
      content-class="tw-top-20 tw-mt-2"
    >
      <div class="tw-min-h-40" data-testid="search-dialog">
        <v-form @submit.prevent="handleSearch" class="tw-mb-10">
          <v-text-field
            v-model.trim="searchTerm"
            :loading="loading"
            theme="light"
            placeholder="Orders, quotes, people,…"
            hide-details
            rounded
            autofocus
          >
            <template #loader="{ isActive }">
              <div class="tw-px-4">
                <v-progress-linear :active="isActive" color="primary" indeterminate rounded />
              </div>
            </template>
          </v-text-field>
        </v-form>
        <!--  -->
        <div v-if="result?.orders?.nodes?.length > 0">
          <h2 class="tw-font-medium tw-mb-1 tw-flex tw-gap-2 tw-items-center">
            <i>
              <CheckCircleIcon class="tw-size-5" />
            </i>
            Orders
          </h2>
          <v-divider />
          <v-list :class="backgroundColors">
            <v-list-item
              v-for="order in result?.orders?.nodes"
              :key="order.id"
              :to="{ name: 'orders', params: { orderId: order.orderNumber } }"
              :active="false"
              @click="searchDialogOpen = false"
            >
              <v-list-item-title class="tw-pl-3">{{ order.orderNumber }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>

        <div v-show="!loading && result?.orders?.nodes?.length === 0" class="tw-mb-4">
          No items match
        </div>

        <!-- route search -->
        <div v-if="filteredSearchRoutes.length">
          <h2 class="tw-font-medium tw-mb-1 tw-flex tw-gap-2 tw-items-center">
            <DocumentTextIcon class="tw-size-4" />
            Pages
          </h2>
          <v-divider class="tw-opacity-100 tw-mb-2" />
          <v-list class="tw-p-0">
            <v-list-item
              v-for="route in filteredSearchRoutes"
              :key="route.name"
              :to="{ name: route.name, hash: route.hash }"
              :active="false"
              @click="searchDialogOpen = false"
            >
              <v-list-item-title class="tw-pl-2">
                {{ getRouteTitle(route) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { onKeyStroke, refDebounced } from '@vueuse/core';
import CommandKeyMacOSIcon from '@/components/icons/command-key-macos.svg?raw';
import Dialog from '@/components/Dialog.vue';
import { useLazyQuery } from '@vue/apollo-composable';
import { SEARCH_ORDER_QUERY } from '@/views/orders/gql/queries';
import { OrderType } from '@/gql/schema';
import { CheckCircleIcon, MagnifyingGlassIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
import { allSearchRoutes, getRouteTitle } from '@/router';
import { useI18n } from 'vue-i18n';
import { useThemeSetting } from '@/composables/useThemeSetting';

const isMacOS = navigator.userAgent.includes('Macintosh');
const searchEl = ref<HTMLInputElement | null>(null);
const searchDialogOpen = ref(false);
const searchTerm = ref('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const { load, loading, result } = useLazyQuery(
  SEARCH_ORDER_QUERY,
  {},
  { fetchPolicy: 'network-only' }
);
const { t } = useI18n();
const { backgroundColors } = useThemeSetting();

const filteredSearchRoutes = computed(() => {
  if (!debouncedSearchTerm.value || debouncedSearchTerm.value.length < 3) {
    return [];
  }

  const debouncedDocumentSearchTerm = debouncedSearchTerm.value.toLowerCase();

  return allSearchRoutes.filter((route) => {
    if (!route.meta?.title) {
      return false;
    }
    return route.meta.title.toString().toLowerCase().includes(debouncedDocumentSearchTerm);
  });
});

onKeyStroke(['k', 'K'], (event) => {
  if ((event.metaKey && isMacOS) || (event.ctrlKey && !isMacOS)) {
    searchDialogOpen.value = true;
    event.preventDefault();
  }
});

function handleSearch() {
  if (!searchTerm.value || loading.value) {
    return;
  }

  load(SEARCH_ORDER_QUERY, {
    filters: { orderNumber: searchTerm.value, orderType: OrderType.Order, isArchived: false },
    first: 20
  });
}
</script>
