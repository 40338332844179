import gql from 'graphql-tag';
import { STATUS_MESSAGE_FIELDS } from './fragments';

export const SET_MESSAGE_READ_MUTATION = gql`
  mutation setStatusMessageIsRead($id: ID!, $isRead: Boolean!) {
    setStatusMessageIsRead(input: { messageId: $id, isRead: $isRead }) {
      __typename
      errors {
        ... on StatusMessageNotFoundError {
          message
        }
      }
    }
  }
`;

export const DELETE_MESSAGE_MUTATION = gql`
  mutation deleteStatusMessage($id: ID!) {
    deleteStatusMessage(input: { messageId: $id }) {
      errors {
        ... on StatusMessageNotFoundError {
          message
        }
        ... on NoAccessToManageStatusMessagesError {
          message
        }
      }
    }
  }
`;

export const CREATE_STATUS_MESSAGE_MUTATION = gql`
  mutation createStatusMessage($input: CreateStatusMessageInput!) {
    createStatusMessage(input: $input) {
      statusMessage {
        ...StatusMessageFields
      }
      errors {
        ... on NoAccessToManageStatusMessagesError {
          message
        }
      }
    }
  }
  ${STATUS_MESSAGE_FIELDS}
`;
