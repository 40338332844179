<template>
  <v-app-bar scroll-behavior="elevate" density="default" color="transparent">
    <template #prepend>
      <router-link :to="{ name: 'dashboard' }">
        <img class="tw-size-10 tw-rounded-full tw-mx-4" src="@/assets/icons/logo.svg" alt="Logo" />
      </router-link>
    </template>
  </v-app-bar>

  <div class="tw-text-center">
    <p class="tw-font-semibold">404</p>
    <h1 class="tw-mt-4 tw-text-3xl tw-font-bold tw-tracking-tight">Page not found</h1>
    <p class="tw-mt-6 tw-leading-7">Sorry, we couldn’t find the page you’re looking for.</p>
    <v-btn :to="{ name: 'dashboard', replace: true }" color="primary" rounded class="tw-mt-10">
      Go back home
    </v-btn>
  </div>
</template>
