<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tw-grid tw-grid-cols-[auto,1fr] tw-gap-10">
    <SettingItem>
      <template #title>
        <span class="tw-inline-flex tw-flex-col">
          <a :href="profileSetting.hash" role="link" class="tw-mt-4 tw-text-base">
            {{ profileSetting.meta.title }}
          </a>
          <v-sheet class="tw-text-sm tw-italic tw-mt-1">
            ({{ profileImageFormat.join(', ').replace(/\./g, '') }})
          </v-sheet>
        </span>
      </template>
      <template #content>
        <div>
          <v-btn
            size="auto"
            variant="text"
            @click="profileInput?.click()"
            :disabled="isUploading"
            :class="['tw-mb-1', { 'tw-animate-pulse': isUploading }]"
            icon
          >
            <v-avatar
              v-if="accountStore.me?.photoUrl"
              size="160"
              class="hover:tw-opacity-70 tw-transition-opacity tw-border"
            >
              <v-img :alt="accountStore.displayName" :src="accountStore.me.photoUrl"></v-img>
            </v-avatar>
            <UserCircleIcon v-else class="tw-size-40" />
          </v-btn>
          <v-file-input
            ref="profileInput"
            class="tw-hidden"
            :accept="profileImageFormat.join(',')"
            @update:modelValue="changeProfile"
          />
          <p class="text-red">{{ profilePhotoError }}</p>
        </div>
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          Email
          <EnvelopeIcon class="tw-size-5" />
        </span>
      </template>
      <template #content>
        <span class="tw-text-base">
          {{ accountStore.user?.attributes?.email }}
        </span>
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          <a :href="languageSetting.hash" role="link"> {{ languageSetting.meta.title }} </a>
          <GlobeAltIcon class="tw-size-5" />
        </span>
      </template>
      <template #content>
        <LanguageSelector
          data-testid="languageSelector"
          :list-props="{ density: 'compact' }"
          class="tw-w-40"
          hide-details
        />
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          <a :href="themeSetting.hash" role="link"> {{ themeSetting.meta.title }} </a>
          <span v-if="!theme.current.value.dark" data-testid="lightThemeIcon">
            <SunIcon class="tw-size-5" />
          </span>
          <span v-else data-testid="darkThemeIcon">
            <MoonIcon class="tw-size-5" />
          </span>
        </span>
      </template>
      <template #content>
        <v-btn-toggle
          :modelValue="theme.global.name.value"
          @update:modelValue="changeTheme"
          variant="outlined"
          mandatory
        >
          <v-btn v-for="themeItem in themes" :key="themeItem.value" :value="themeItem.value">
            {{ themeItem.title }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </SettingItem>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  GlobeAltIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
  EnvelopeIcon
} from '@heroicons/vue/24/outline';
import LanguageSelector from '@/components/Layout/components/LanguageSelector.vue';
import { useThemeSetting } from '@/composables/useThemeSetting';
import { useAccountStore } from '@/stores/account';
import SettingItem from '@/views/settings/components/settingItem.vue';

const accountStore = useAccountStore();
const auth = useAuth();

const { themes, changeTheme, theme } = useThemeSetting();
const profileInput = ref<HTMLInputElement | null>(null);
const isUploading = ref(false);
const profilePhotoError = ref('');
const profileImageFormat = ['.jpg', '.jpeg', '.png', '.webp'];

// https://stagingapi.nordan.no/user/spec/#tag/user/paths/~1profilePhotos~1%7BuserId%7D/post
const profileImageMaxSize = 10_485_760; // 10MB

async function changeProfile(file: File | File[]) {
  if (!file || Array.isArray(file)) {
    return;
  }

  if (file.size > profileImageMaxSize) {
    profilePhotoError.value = `Maximum photo size ${Math.ceil(profileImageMaxSize / 1_048_576)}MB`;
    return;
  }

  profilePhotoError.value = '';
  isUploading.value = true;

  try {
    await postRequest(`/user/profilePhotos/${accountStore.user?.id}`, file);
    await auth.getUserAccess();
    if (accountStore.me) {
      // append timestamp to invalidate image cache
      accountStore.set('me', {
        ...accountStore.me,
        photoUrl: accountStore.me.photoUrl + `?${Date.now()}`
      });
    }
  } finally {
    isUploading.value = false;
  }
}
</script>

<script lang="ts">
import { settingsRouteNames } from '@/views/settings/routes';
import { postRequest } from '@/utils/httpClient';
import { useAuth } from '@/composables/useAuth';

const profileSetting = {
  hash: '#profile',
  name: settingsRouteNames.general,
  meta: { title: 'Profile' }
};
const themeSetting = {
  hash: '#theme',
  name: settingsRouteNames.general,
  meta: { title: 'Theme' }
};
const languageSetting = {
  hash: '#language',
  name: settingsRouteNames.general,
  meta: { title: 'Language' }
};
export const generalSettingSearchRoutes = [profileSetting, themeSetting, languageSetting];
</script>
