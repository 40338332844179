import gql from 'graphql-tag';

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    name
    customerNumber
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    username
    photoUrl
  }
`;

export const ORDER_DOCUMENT_FIELDS = gql`
  fragment OrderDocumentFields on OrderDocument {
    id
    language
    extension
    description
    downloadUrl
    orderType
    version
    numBytes
    updatedAt
    updatedBy {
      firstName
      lastName
    }
    type
    printType
    frontPage {
      id
      customer {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    id
    projectName
    remarks
    createdAt
    updatedAt
    orderNumber
    isFavorite
    orderType
    customer {
      ...CustomerFields
    }
    customerOwner {
      ...CustomerFields
    }
    salesPerson {
      ...UserFields
    }
    quoteCustomers {
      ...CustomerFields
    }
    priceList
    priceType
    gmrWithBonus
    gmrWithoutBonus
    netSalesCostPrice
    customerCurrency
    netCustomerPrice
    vatCountry
    vatRate
    markupRate
    statusIcon
    lockedBy {
      ...UserFields
    }
    followUpDate
    correctionDeadline
    fmsStatus
  }
  ${CUSTOMER_FIELDS}
  ${USER_FIELDS}
`;
