import { useAccountStore } from '@/stores/account';
import type { RouteRecordRaw } from 'vue-router';

export const settingsRouteNames = {
  general: 'settings-general',
  account: 'settings-account',
  system: 'settings-system',
  statusMessages: 'settings-status-messages'
} as const;

const defaultPath = '/settings';

export default {
  path: defaultPath,
  name: 'settings',
  meta: {
    title: 'Settings'
  },
  component: () => import('@/views/settings/settings.vue'),
  children: [
    {
      path: 'general',
      name: settingsRouteNames.general,
      meta: {
        title: 'Settings general'
      },
      alias: defaultPath,
      component: () => import('@/views/settings/general/general.vue')
    },
    {
      path: 'account',
      name: settingsRouteNames.account,
      meta: {
        title: 'Settings account'
      },
      component: () => import('@/views/settings/account/account.vue')
    },
    {
      path: 'system',
      name: settingsRouteNames.system,
      meta: {
        title: 'Settings system'
      },
      component: () => import('@/views/settings/system/system.vue')
    },
    {
      path: 'status-messages',
      name: settingsRouteNames.statusMessages,
      meta: {
        title: 'Settings status messages'
      },
      beforeEnter: (to) => {
        const accountStore = useAccountStore();
        to.meta.authorized = accountStore.getUserAccess('statusMessage', 'canManageStatusMessages');
        return true;
      },
      component: () => import('@/views/settings/statusMessages/statusMessages.vue')
    }
  ]
} as RouteRecordRaw;
