<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-alert :class="backgroundColors" variant="outlined" class="tw-border-gray-600">
    <div v-if="!item.isRead">
      <p class="tw-mb-3">{{ item.text }}</p>
      <v-btn
        variant="tonal"
        size="small"
        @click="updateReadStatus(!item.isRead)"
        :disabled="loading"
        rounded
      >
        Mark read
      </v-btn>
    </div>
    <div v-else class="tw-flex tw-items-center tw-gap-4">
      Message marked as read
      <v-btn
        variant="tonal"
        size="small"
        @click="updateReadStatus(!item.isRead)"
        :disabled="loading"
        class="tw-ml-auto"
        rounded
      >
        Undo
      </v-btn>
    </div>
    <div v-if="item.isRead" class="tw-absolute tw-bottom-0 tw-inset-x-0 tw-h-[2px]">
      <div ref="progressBar" class="tw-size-full bg-primary -tw-translate-x-full progress-bar" />
    </div>
  </v-alert>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, useTemplateRef } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { useThemeSetting } from '@/composables/useThemeSetting';
import { SET_MESSAGE_READ_MUTATION } from '@/gql/mutations';
import type { StatusMessage } from '@/gql/schema';

const { item } = defineProps<{
  item: StatusMessage;
}>();

const emit = defineEmits<{
  (e: 'dismiss'): void;
  (e: 'read'): void;
  (e: 'undo'): void;
}>();

const { mutate: setMessageIsRead, loading } = useMutation(SET_MESSAGE_READ_MUTATION);
const { backgroundColors } = useThemeSetting();
const progressBar = useTemplateRef<HTMLDivElement>('progressBar');
const timeout = 5000;
const progressDuration = `${timeout}ms`;
let dismissTimeoutId = 0;

async function updateReadStatus(isRead: boolean) {
  if (loading.value) return;

  clearDismissTimeout();

  await setMessageIsRead({ id: item.id, isRead });
  if (isRead) {
    emit('read');
  } else {
    emit('undo');
  }
}

function clearDismissTimeout() {
  if (dismissTimeoutId) clearTimeout(dismissTimeoutId);
}

onMounted(() => {
  if (item.isRead) {
    dismissTimeoutId = window.setTimeout(() => {
      emit('dismiss');
    }, timeout);

    setTimeout(() => {
      progressBar.value?.classList.add('active');
    }, 100);
  }
});

onBeforeUnmount(() => {
  clearDismissTimeout();
});
</script>

<style scoped>
.progress-bar {
  transition: transform v-bind(progressDuration) linear;
  &.active {
    transform: translateX(0) !important;
  }
}
</style>
