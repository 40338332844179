<template>
  <v-layout class="tw-min-h-screen">
    <router-view v-if="route.meta.layout === null" />
    <component v-else :is="LayoutComponent">
      <router-view :key="route.name?.toString()" />
    </component>
    <NewUpdateSnackbar v-model="hasNewVersion" @reload="reload" />
    <ErrorSnackbar
      v-model="hasError"
      :error="appStore.error"
      @update:model-value="appStore.setError(null)"
    />
    <StatusMessages v-if="accountStore.authenticated && route.name" />
  </v-layout>
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router';
import { computed, ref, watchEffect, watch, onErrorCaptured } from 'vue';
import { useAppStore } from '@/stores/app';
import MainLayout from '@/components/Layout/MainLayout.vue';
import ContentLayout from '@/components/Layout/ContentLayout.vue';
import { useCheckForUpdates } from '@/composables/useCheckForUpdates';
import NewUpdateSnackbar from '@/components/NewUpdateSnackbar.vue';
import StatusMessages from '@/components/statusMessages/statusMessages.vue';
import ErrorSnackbar from '@/components/ErrorSnackbar.vue';
import { loadLocaleMessages } from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { useAccountStore } from './stores/account';
import { isDev } from './utils/env';
import { isAxiosError } from 'axios';
import * as Sentry from '@sentry/vue';

const route = useRoute();
const router = useRouter();
const accountStore = useAccountStore();
const appStore = useAppStore();
const { hasNewVersion } = useCheckForUpdates(60 * 1000);
const hasError = ref(false);
const { t } = useI18n();

const LayoutComponent = computed(() => {
  if (!route.name) return null;
  return route.meta.layout === 'content' ? ContentLayout : MainLayout;
});

function reload() {
  window.location.reload();
}

async function loadMessages() {
  const stringGroups = new Set(['PI5', 'piCountry_Name', 'piFactoryWorkstation_Name']);

  route.matched.forEach((routeRecord: any) => {
    if (routeRecord.meta.stringGroups && routeRecord.meta.stringGroups.length > 0) {
      routeRecord.meta.stringGroups.forEach((group: any) => stringGroups.add(group));
    }
  });

  loadLocaleMessages(appStore.locale, stringGroups);
}

watchEffect(() => {
  if (route.meta.title) document.title = `PI5 - ${t(route.meta.title as string)}`;
});

watchEffect(() => {
  hasError.value = Boolean(appStore.error);
});

watch(() => appStore.locale, loadMessages);

watch(
  route,
  (value) => {
    if (value) loadMessages();
  },
  { immediate: true }
);

onErrorCaptured((error) => {
  if (!isAxiosError(error) || !error.response) {
    return;
  }

  const errorResponseStatus = error.response.status;

  if (!isDev && ![401, 403].includes(errorResponseStatus)) {
    Sentry.captureException(error);
  }

  if (errorResponseStatus === 401) {
    const accountStore = useAccountStore();
    accountStore.reset();
    router.replace({ name: 'login', query: { redirectTo: router.currentRoute.value.fullPath } });
  } else if (errorResponseStatus !== 403) {
    const appStore = useAppStore();
    appStore.setError(error);
  }
});
</script>
