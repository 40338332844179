<template>
  <v-snackbar
    :modelValue="sortedUnreadMessages.length > 0"
    :timeout="-1"
    variant="text"
    location="top right"
  >
    <div class="tw-grid tw-gap-2">
      <Message
        v-for="message in sortedUnreadMessages"
        :key="message.id"
        :item="message"
        @read="removeUnreadMessage(message)"
      />
    </div>
  </v-snackbar>
  <v-snackbar
    :modelValue="readMessages.length > 0"
    :timeout="-1"
    variant="text"
    location="bottom right"
  >
    <div class="tw-grid tw-gap-2">
      <Message
        v-for="message in readMessages"
        :key="message.id"
        :item="message"
        @undo="removeReadMessage(message)"
        @dismiss="removeReadMessage(message, false)"
      />
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useQuery, useSubscription } from '@vue/apollo-composable';
import { STATUS_MESSAGE_SUBSCRIPTION } from '@/gql/subscriptions';
import { STATUS_MESSAGE_QUERY } from '@/gql/queries';
import Message from './message.vue';
import { type Query, type StatusMessage, type Subscription } from '@/gql/schema';

const { onResult: setNewStatusMessages } = useSubscription<Subscription>(
  STATUS_MESSAGE_SUBSCRIPTION
);
const { onResult: setInitialMessages } = useQuery<Query>(STATUS_MESSAGE_QUERY, {
  filter: { includeRead: false }
});
const unreadMessages = ref<StatusMessage[]>([]);
const readMessages = ref<StatusMessage[]>([]);

const sortedUnreadMessages = computed(() => {
  const clonedMessages = [...unreadMessages.value];

  clonedMessages.sort((m1, m2) => {
    return new Date(m2.createdAt).getTime() - new Date(m1.createdAt).getTime();
  });

  return clonedMessages;
});

setInitialMessages((results) => {
  if (results.data?.statusMessages) {
    unreadMessages.value = [...results.data.statusMessages];
  }
});

setNewStatusMessages((results) => {
  if (results.data?.statusMessagePublished.message) {
    unreadMessages.value.push(results.data.statusMessagePublished.message);
  }
});

function removeUnreadMessage(message: StatusMessage) {
  unreadMessages.value = unreadMessages.value.filter((m) => m.id !== message.id);
  const clonedMessage = { ...message, isRead: true };
  readMessages.value.push(clonedMessage);
}

function removeReadMessage(message: StatusMessage, canMarkRead = true) {
  readMessages.value = readMessages.value.filter((m) => m.id !== message.id);
  if (canMarkRead) {
    const clonedMessage = { ...message, isRead: false };
    unreadMessages.value.push(clonedMessage);
  }
}
</script>
