<template>
  <Dialog :can-close="false" width="unset" max-width="700">
    <template #title> Connect account </template>
    <p class="tw-mb-4">
      Are you sure you want to connect the PI account
      <span class="tw-font-medium">{{ accountStore.user?.attributes?.username }}</span> to the
      NorDan Entra ID <span class="tw-font-medium"> {{ entraUserPrincipalName }} </span>?
    </p>
    <p>If not, sign out and try again with the correct accounts.</p>
    <template #actions="{ closeDialog }">
      <v-btn
        @click="
          closeDialog();
          cancelAndSignOut();
        "
        class="tw-mr-4"
      >
        Cancel and sign out
      </v-btn>
      <v-btn color="primary" @click="emit('connect')" variant="flat" :loading="isConnecting">
        Connect these accounts
      </v-btn>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/Dialog.vue';
import { useRoute, useRouter } from 'vue-router';
import { useSessionStorage } from '@vueuse/core';
import { useAuth } from '@/composables/useAuth';
import { computed, unref } from 'vue';
import { useAccountStore } from '@/stores/account';

const emit = defineEmits<{
  (e: 'connect'): void;
}>();

defineProps<{
  isConnecting: boolean;
  entraUserPrincipalName: string;
}>();

const auth = useAuth();
const accountStore = useAccountStore();

async function cancelAndSignOut() {
  await auth.logout();
  window.location.href = microsoftSignOutUrl;
}
</script>

<script lang="ts">
const redirectUrl = `${import.meta.env.VITE_BASE_URL}/login`;
const microsoftSignInLink = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize';
const microsoftSignOutUrl = 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0';
const msId = 'msigninsid';

export function useConnectToMicrosoftAccount() {
  const route = useRoute();
  const router = useRouter();
  const microsoftSignInSessionId = useSessionStorage(msId, crypto.randomUUID());

  const isRedirectedFromMicrosoft = computed(() => {
    return Boolean(
      route.query.code &&
        route.query.session_state &&
        route.query.state &&
        redirectedState.value.sessionId === microsoftSignInSessionId.value
    );
  });

  const redirectedState = computed<Record<string, any>>(() => {
    let state = {};
    try {
      state =
        route.query.state && !Array.isArray(route.query.state) ? JSON.parse(route.query.state) : {};
    } catch (e) {
      //
    }
    return state;
  });

  function redirectToMicrosoft(stateParams: Record<string, any> = {}) {
    const state: Record<string, string> = Object.assign(
      {
        sessionId: unref(microsoftSignInSessionId.value),
        redirectTo: redirectedState.value.redirectTo
      },
      stateParams
    );
    const link = new URL(microsoftSignInLink);
    link.searchParams.append('client_id', '8d9ebc61-62c3-47de-a3f0-9efe04c01095');
    link.searchParams.append('response_type', 'code');
    link.searchParams.append('response_mode', 'query');
    link.searchParams.append('scope', 'user.read');
    link.searchParams.append('redirect_uri', encodeURI(redirectUrl));
    link.searchParams.append('state', JSON.stringify(state));
    window.location.href = link.toString();
  }

  function signOutFromMicrosoft() {
    router.replace({ name: 'login' });
    window.location.href = microsoftSignOutUrl;
  }

  function clearSessionId() {
    sessionStorage.removeItem(msId);
  }

  return {
    isRedirectedFromMicrosoft,
    redirectUrl,
    redirectedState,
    clearSessionId,
    signOutFromMicrosoft,
    redirectToMicrosoft
  };
}
</script>
