import gql from 'graphql-tag';

export const STATUS_MESSAGE_SUBSCRIPTION = gql`
  subscription statusMessageSubscription {
    statusMessagePublished {
      message {
        audience
        createdAt
        id
        isRead
        showUntil
        text
      }
    }
  }
`;
