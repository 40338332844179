<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tw-grid tw-grid-cols-[auto,1fr] tw-gap-10 tw-gap-y-12">
    <SettingItem>
      <template #title>
        <span>
          <a :href="accountSetting.hash" role="link" class="tw-text-base">
            {{ accountSetting.meta.title }}
          </a>
        </span>
      </template>
      <template #content>
        <div>
          <fieldset class="tw-w-80">
            <v-text-field placeholder="Current password" type="password" />
            <v-text-field placeholder="Repeat password" type="password" />
          </fieldset>
          <!-- CTAs -->
          <v-btn color="primary" rounded> {{ $t(`PI5['account.save']`) }} </v-btn>
        </div>
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-self-start"> Connected accounts </span>
      </template>
      <template #content>
        <div class="tw-grid tw-gap-4">
          <div class="tw-flex tw-gap-2 tw-items-center">
            <span v-html="MicrosoftIcon"></span>
            <span>
              Microsoft Entra
              <span class="tw-text-sm tw-opacity-75">
                ({{ isConnectedToEntra ? 'Connected' : 'Not connected' }})
              </span>
            </span>
          </div>
          <span>
            <v-btn
              v-if="isConnectedToEntra"
              color="primary"
              :loading="disconnectingFromEntra"
              @click="disconnectFromEntra"
              rounded
            >
              <template #prepend>
                <LinkSlashIcon class="tw-size-4" />
              </template>
              Disconnect
            </v-btn>
            <v-btn
              v-else
              color="primary"
              @click="redirectToMicrosoft({ redirectTo: route.fullPath, autoConnect: true })"
              rounded
            >
              <template #prepend>
                <LinkIcon class="tw-size-4" />
              </template>
              Connect
            </v-btn>
          </span>
        </div>
      </template>
    </SettingItem>
  </div>
</template>

<script lang="ts" setup>
import { useAccountStore } from '@/stores/account';
import { useRoute } from 'vue-router';
import { useConnectToMicrosoftAccount } from '@/components/connectMicrosoftAccountConfirmDialog.vue';

const route = useRoute();
const accountStore = useAccountStore();
const isConnectedToEntra = ref(Boolean(accountStore.user?.attributes?.isConnectedToEntra));
const disconnectingFromEntra = ref(false);
const { redirectToMicrosoft } = useConnectToMicrosoftAccount();

async function disconnectFromEntra() {
  if (disconnectingFromEntra.value) return;

  try {
    disconnectingFromEntra.value = true;
    await postRequest('/auth/disconnectFromEntra');
    isConnectedToEntra.value = false;
    disconnectingFromEntra.value = false;
    const user = { ...accountStore.user };
    if (user.attributes && 'isConnectedToEntra' in user.attributes) {
      user.attributes.isConnectedToEntra = false;
    }
    accountStore.set('user', user);
  } finally {
    disconnectingFromEntra.value = false;
  }
}
</script>

<script lang="ts">
import { settingsRouteNames } from '@/views/settings/routes';
import SettingItem from '@/views/settings/components/settingItem.vue';
import { LinkIcon, LinkSlashIcon } from '@heroicons/vue/24/outline';
import MicrosoftIcon from '@/assets/icons/login/microsoft.svg?raw';
import { ref } from 'vue';
import { postRequest } from '@/utils/httpClient';

const accountSetting = {
  hash: '#password',
  name: settingsRouteNames.account,
  meta: {
    title: 'Change password'
  }
};
export const accountSettingSearchRoutes = [accountSetting];
</script>
