<template>
  <v-snackbar v-if="error" multi-line content-class="tw-p-2">
    <v-card color="transparent" class="tw-shadow-none">
      <v-card-title class="tw-p-0">{{ error.response.statusText }}</v-card-title>
      <v-card-text
        v-for="(err, index) of error.response.data.errors"
        :key="err.id"
        class="tw-text-gray-200 tw-p-0 tw-break-all"
      >
        <v-divider v-if="index !== 0" class="tw-my-2"></v-divider>
        <div class="tw-text-base tw-mb-1">{{ err.title }} #{{ err.status }}</div>
        <div>
          {{ err.detail }}
        </div>
        <div v-if="err.source?.pointer">{{ err.source.pointer }}</div>
        <div v-if="err.source?.parameter">{{ err.source.parameter }}</div>
        <div v-if="err.source?.header">{{ err.source.header }}</div>
      </v-card-text>
    </v-card>
    <template v-slot:actions>
      <div class="tw-flex tw-flex-col tw-gap-2">
        <v-btn color="red-lighten-1" variant="outlined" @click="emits('update:model-value', false)">
          Close
          <!-- {{ t('PortalOffer.Lukk') }} -->
        </v-btn>
        <v-btn @click="copy(JSON.stringify(error.response, null, 2))">
          Copy
          <!-- {{ t('PortalOffer.Kopier') }} -->
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core';

defineProps<{
  error: any;
}>();

const emits = defineEmits<{
  (e: 'update:model-value', value: boolean): void;
}>();

const { copy } = useClipboard();
</script>
