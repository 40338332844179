import gql from 'graphql-tag';

export const PAGE_INFO_FIELDS = gql`
  fragment PageInfoFields on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const STATUS_MESSAGE_FIELDS = gql`
  fragment StatusMessageFields on StatusMessage {
    id
    text
    createdAt
    audience
    isRead
    showUntil
  }
`;
